import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { OTPUtils } from '../components/OneTimePurchase';
import { OTPCheckoutSchedulePreCall } from '../components/OneTimePurchase/OTPCheckoutSchedulePreCall';
import { useCheckoutLoaderData } from './checkout.client';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);

  return {
    headcount: OTPUtils.ParseHeadcount(url.searchParams.get('headcount')),
  };
};

export function Component() {
  const { pack } = useCheckoutLoaderData();
  const props = useLoaderData<typeof clientLoader>();

  return <OTPCheckoutSchedulePreCall {...props} pack={pack} />;
}
