import { Link } from '@remix-run/react';
import { useState } from 'react';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import config from '../../config';
import { fromDTOGamePack } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { GamePackUtils } from '../Game/GamePack/utils';
import { HubspotMeetingsForm } from '../Marketing/HubspotMeetingsForm';
import { OTPCheckoutCustomProvider } from './OTPCheckoutCustomProvider';
import { OTPCheckoutLayout } from './OTPCheckoutLayout';
import { OTPCheckoutSidebar } from './OTPCheckoutSidebar';
import { OTPUtils } from './utils';

function Scheduled(props: { pack: DtoGamePack }) {
  const { pack } = props;
  return (
    <OTPCheckoutLayout pack={pack} progress={80} background={false}>
      <div className='w-full flex justify-center items-center text-white'>
        <div className='w-140 bg-modal rounded-2.5xl border border-secondary px-16 py-10 gap-10 flex flex-col items-center'>
          <div className='text-tertiary font-medium text-2xl text-center'>
            Your call has been scheduled!
          </div>
          <img
            alt='calendar'
            src={getStaticAssetPath('images/calendar-3d-icon.png')}
            className='w-25 h-25'
          />
          <div className='text-center w-72'>
            <strong>
              You’ll receive an email invite giving you details on how to join
              the call.
            </strong>
            <br />
            In the meantime, check out our full library of team building
            experiences!
          </div>

          <Link
            to='/home'
            className='w-96 h-12.5 btn-primary flex items-center justify-center'
          >
            Browse Library
          </Link>
        </div>
      </div>
    </OTPCheckoutLayout>
  );
}

export function OTPCheckoutSchedulePreCall(props: {
  pack: DtoGamePack;
  headcount: number;
}) {
  const { pack, headcount } = props;

  const analytics = useOneTimePurchaseAnalytics();
  const [showScheduled, setShowScheduled] = useState(false);

  const handleSubmit = () => {
    analytics.trackEventPurchasePreCallScheduled({
      type: OTPUtils.GamePackType(pack),
    });

    setTimeout(() => {
      setShowScheduled(true);
    }, 3000);
  };

  if (showScheduled) {
    return <Scheduled pack={pack} />;
  }

  const hubspotUrl = new URL(config.hubspot.eventPurchasePreCallUrl);
  hubspotUrl.searchParams.set('team_size', headcount.toString());

  const packPrice = GamePackUtils.FindOneTimePrice(pack, headcount);

  if (!packPrice) {
    return null;
  }

  const handleHeadcountChange = (headcount: number) => {
    const price = GamePackUtils.FindOneTimePrice(pack, headcount);

    const url = new URL(window.location.href);
    url.searchParams.set('headcount', headcount.toString());
    url.searchParams.set('price-id', price?.id || '');
    window.location.replace(url.toString());
  };

  return (
    <OTPCheckoutLayout
      pack={pack}
      progress={20}
      checkAccessible
      containerClassName='!w-full h-full !items-start'
      background={false}
    >
      <OTPCheckoutCustomProvider
        pack={fromDTOGamePack(pack)}
        price={packPrice}
        returnUrl={''}
      >
        <OTPCheckoutSidebar
          headcount={headcount}
          pack={fromDTOGamePack(pack)}
          onHeadcountChange={handleHeadcountChange}
          prePayment
        />

        <div className='mr-30 2xl:mr-50 mb-5 flex flex-col text-white'>
          <p className='text-xl font-bold'>Schedule your call</p>
          <HubspotMeetingsForm
            title='Schedule Your Call'
            src={hubspotUrl.toString()}
            className='w-180 2xl:w-210 h-154 2xl:h-180'
            onSubmitted={handleSubmit}
          />
        </div>
      </OTPCheckoutCustomProvider>
    </OTPCheckoutLayout>
  );
}
